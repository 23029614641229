import Config from './websiteConfig';

export const getHostUrl = (): string => {
  return Config.siteUrl;
};

export const buildUrl = (path: string): string => {
  if (!path) {
    return getHostUrl();
  }

  if (path.startsWith('/')) {
    return [getHostUrl(), path].join('');
  }

  return [getHostUrl(), '/', path].join('');
};
