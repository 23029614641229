// @ts-expect-error No typescript for gallery
import Gallery from '@browniebroke/gatsby-image-gallery';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import * as _ from 'lodash';
import { lighten, setLightness } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet';

import '@browniebroke/gatsby-image-gallery/dist/style.css';

import { AuthorList } from '../components/AuthorList';
import { Footer } from '../components/Footer';
import PostContent from '../components/PostContent';
import { ReadNext } from '../components/ReadNext';
import { Wrapper } from '../components/Wrapper';
import SiteNav, { SiteNavMain } from '../components/header/SiteNav';
import { ProgressBar } from '../components/progress/ProgressBar';
import MicrodataHelmet from '../components/seo/MicrodataHelmet';
import { Subscribe } from '../components/subscribe/Subscribe';
import Microdata from '../configs/microdata';
import { buildUrl } from '../configs/siteUrl';
import Config from '../configs/websiteConfig';
import { IBlogPostHeader } from '../graphql/blogPostHeaderQuery';
import { IBlogPost, PreviewBlogPost } from '../graphql/blogPostQuery';
import IndexLayout from '../layouts';
import { Translate } from '../locales/i18n';
import { colors } from '../styles/colors';
import { inner, outer, SiteMain } from '../styles/shared';
interface PageTemplateProps {
  location: Location;
  data: {
    markdownRemark: IBlogPost;
    relatedPosts: {
      totalCount: number;
      edges: Array<{
        node: IBlogPostHeader;
      }>;
    };
  };
  pageContext: {
    prev: PreviewBlogPost;
    next: PreviewBlogPost;
  };
}

const PageTemplate = ({ data, pageContext, location }: PageTemplateProps): JSX.Element => {
  const post = data.markdownRemark;

  let width = '';
  let height = '';
  if (post.frontmatter.image?.childImageSharp) {
    width = post.frontmatter.image.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0];
    height = String(Number(width) / post.frontmatter.image.childImageSharp.fluid.aspectRatio);
  }

  const date = new Date(post.frontmatter.date);
  // 2018-08-20
  const datetime = format(date, 'yyyy-MM-dd');
  // 20 AUG 2018
  const displayDatetime = format(date, 'dd LLL yyyy');

  const tags = post.frontmatter.tags ?? [];

  const images = post.frontmatter.gallery?.map(node => node.childImageSharp);

  return (
    <IndexLayout className="post-template">
      <Helmet>
        <html lang={Config.lang} />
        <title>{post.frontmatter.title}</title>

        <meta name="description" content={post.frontmatter.excerpt || post.excerpt} />
        <meta property="og:site_name" content={Config.title} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.frontmatter.title} />
        <meta property="og:description" content={post.frontmatter.excerpt || post.excerpt} />
        <meta property="og:url" content={Config.siteUrl + location.pathname} />
        {post.frontmatter.image?.childImageSharp && (
          <meta
            property="og:image"
            content={`${Config.siteUrl}${post.frontmatter.image.childImageSharp.fluid.src}`}
          />
        )}
        <meta property="article:published_time" content={post.frontmatter.date} />
        {/* not sure if modified time possible */}
        {/* <meta property="article:modified_time" content="2018-08-20T15:12:00.000Z" /> */}
        {post.frontmatter.tags && (
          <meta property="article:tag" content={post.frontmatter.tags[0]} />
        )}

        {Config.externalUrls.facebook && (
          <meta property="article:publisher" content={Config.externalUrls.facebook} />
        )}
        {Config.externalUrls.facebook && (
          <meta property="article:author" content={Config.externalUrls.facebook} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.frontmatter.title} />
        <meta name="twitter:description" content={post.frontmatter.excerpt || post.excerpt} />
        <meta name="twitter:url" content={Config.siteUrl + location.pathname} />
        {post.frontmatter.image?.childImageSharp && (
          <meta
            name="twitter:image"
            content={`${Config.siteUrl}${post.frontmatter.image.childImageSharp.fluid.src}`}
          />
        )}
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content={post.frontmatter.author[0].id} />
        <meta name="twitter:label2" content="Filed under" />
        {post.frontmatter.tags && <meta name="twitter:data2" content={post.frontmatter.tags[0]} />}
        {Config.externalUrls.twitter && (
          <meta
            name="twitter:site"
            content={`@${Config.externalUrls.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        {Config.externalUrls.twitter && (
          <meta
            name="twitter:creator"
            content={`@${Config.externalUrls.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        {width && <meta property="og:image:width" content={width} />}
        {height && <meta property="og:image:height" content={height} />}
      </Helmet>
      <MicrodataHelmet
        microdata={Microdata.generateArticleMicrodata({
          title: post.frontmatter.title,
          tags: post.frontmatter.tags,
          postedAt: post.frontmatter.date,
          updatedAt: post.parent.modifiedTime,
          author: post.frontmatter.author[0].id,
          authorPage: `/author/${_.kebabCase(post.frontmatter.author[0].id)}/`, // TODO check for better helper
          image:
            post.frontmatter.image && buildUrl(post.frontmatter.image.childImageSharp.fluid.src),
          pageUrl: buildUrl(location.pathname),
        })}
      />
      <Wrapper>
        <header className="site-header">
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isPost post={post.frontmatter} />
            </div>
          </div>
          <ProgressBar />
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            {/* TODO: no-image css tag? */}
            <article css={[PostFull, !post.frontmatter.image && NoImage]}>
              <PostFullHeader className="post-full-header">
                <PostFullTags className="post-full-tags">
                  {tags.slice(0, 5).map(tag => (
                    <Link key={tag} to={`/tags/${_.kebabCase(tag)}/`}>
                      {tag}
                    </Link>
                  ))}
                </PostFullTags>
                <PostFullTitle className="post-full-title">{post.frontmatter.title}</PostFullTitle>
                <PostFullCustomExcerpt className="post-full-custom-excerpt">
                  {post.frontmatter.excerpt}
                </PostFullCustomExcerpt>
                <PostFullByline className="post-full-byline">
                  <section className="post-full-byline-content">
                    <AuthorList authors={post.frontmatter.author} tooltip="large" />
                    <section className="post-full-byline-meta">
                      <h4 className="author-name">
                        {post.frontmatter.author.map(author => (
                          <Link key={author.id} to={`/author/${_.kebabCase(author.id)}/`}>
                            {author.id}
                          </Link>
                        ))}
                      </h4>
                      <div className="byline-meta-content">
                        <time className="byline-meta-date" dateTime={datetime}>
                          {displayDatetime}
                        </time>
                        <span className="byline-reading-time">
                          <span className="bull">&bull;</span> {post.timeToRead}{' '}
                          {Translate.action.minRead}
                        </span>
                      </div>
                    </section>
                  </section>
                </PostFullByline>
              </PostFullHeader>

              {post.frontmatter.image?.childImageSharp && (
                <PostFullImage>
                  <Img
                    style={{ height: '100%' }}
                    fluid={post.frontmatter.image.childImageSharp.fluid}
                    alt={post.frontmatter.title}
                  />
                </PostFullImage>
              )}
              <PostContent htmlAst={post.htmlAst} />

              {images && (
                <PostGallery>
                  <Gallery images={images} />
                </PostGallery>
              )}

              {post.frontmatter.footer && (
                <PostFooter>
                  <p>{post.frontmatter.footer}</p>
                </PostFooter>
              )}
              <br />

              {/* The big email subscribe modal content */}
              {Config.showSubscribe && <Subscribe title={Config.title} />}
            </article>
          </div>
        </main>

        <ReadNext
          currentPageSlug={location.pathname}
          tags={post.frontmatter.tags}
          relatedPosts={data.relatedPosts}
          pageContext={pageContext}
        />

        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};
export const PostFull = css`
  position: relative;
  z-index: 50;
`;

export const NoImage = css`
  .post-full-content {
    padding-top: 0;
  }

  .post-full-content:before,
  .post-full-content:after {
    display: none;
  }
`;

export const PostFullHeader = styled.header`
  position: relative;
  margin: 0 auto;
  padding: 70px 170px 50px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: 1170px) {
    padding: 60px 11vw 50px;
  }

  @media (max-width: 800px) {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  @media (max-width: 500px) {
    padding: 20px 0 35px;
  }
`;

const PostFullTags = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* color: var(--midgrey); */
  color: ${colors.midgrey};
  font-size: 1.3rem;
  line-height: 1.4em;
  font-weight: 600;
  text-transform: uppercase;

  > *:not(:first-of-type):before {
    content: ',';
    margin-right: 3px;
  }
`;
const PostGallery = styled.div`
  margin-bottom: 30px;
`;

const PostFullCustomExcerpt = styled.p`
  margin: 20px 0 0;
  color: ${colors.midgrey};
  font-family: Georgia, serif;
  font-size: 2.3rem;
  line-height: 1.4em;
  font-weight: 300;

  @media (max-width: 500px) {
    font-size: 1.9rem;
    line-height: 1.5em;
  }

  @media (prefers-color-scheme: dark) {
    /* color: color(var(--midgrey) l(+10%)); */
    color: ${lighten('0.1', colors.midgrey)};
  }
`;

const PostFooter = styled.div`
  color: ${colors.midgrey};
  font-family: Georgia, serif;
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: 300;
  text-align: center;
  word-break: break-word;

  @media (prefers-color-scheme: dark) {
    /* color: color(var(--midgrey) l(+10%)); */
    color: ${lighten('0.1', colors.midgrey)};
  }
`;

const PostFullByline = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 35px 0 0;
  padding-top: 15px;
  /* border-top: 1px solid color(var(--lightgrey) l(+10%)); */
  border-top: 1px solid ${lighten('0.1', colors.lightgrey)};

  .post-full-byline-content {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
  }

  .post-full-byline-content .author-list {
    justify-content: flex-start;
    padding: 0 12px 0 0;
  }

  .post-full-byline-meta {
    margin: 2px 0 0;
    /* color: color(var(--midgrey) l(+10%)); */
    color: ${lighten('0.1', colors.midgrey)};
    font-size: 1.2rem;
    line-height: 1.2em;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  .post-full-byline-meta h4 {
    margin: 0 0 3px;
    font-size: 1.3rem;
    line-height: 1.4em;
    font-weight: 500;
  }

  .post-full-byline-meta h4 a {
    /* color: color(var(--darkgrey) l(+10%)); */
    color: ${lighten('0.1', colors.darkgrey)};
  }

  .post-full-byline-meta h4 a:hover {
    /* color: var(--darkgrey); */
    color: ${colors.darkgrey};
  }

  .post-full-byline-meta .bull {
    display: inline-block;
    margin: 0 4px;
    opacity: 0.6;
  }

  @media (prefers-color-scheme: dark) {
    /* border-top-color: color(var(--darkmode) l(+15%)); */
    border-top-color: ${lighten('0.15', colors.darkmode)};

    .post-full-byline-meta h4 a {
      color: rgba(255, 255, 255, 0.85);
    }

    .post-full-byline-meta h4 a:hover {
      color: #fff;
    }
  }
`;

export const PostFullTitle = styled.h1`
  margin: 0 0 0.2em;
  color: ${setLightness('0.05', colors.darkgrey)};
  @media (max-width: 500px) {
    margin-top: 0.2em;
    font-size: 3.3rem;
  }

  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.9);
  }
`;

const PostFullImage = styled.figure`
  margin: 25px 0 50px;
  height: 800px;
  background: ${colors.lightgrey} center center;
  background-size: cover;
  border-radius: 5px;

  @media (max-width: 1170px) {
    margin: 25px -6vw 50px;
    border-radius: 0;
    img {
      max-width: 1170px;
    }
  }

  @media (max-width: 800px) {
    height: 400px;
  }
  @media (max-width: 500px) {
    margin-bottom: 4vw;
    height: 350px;
  }
`;

export const pageQuery = graphql`
  query($slug: String, $primaryTag: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      ...BlogPost
    }
    relatedPosts: allMarkdownRemark(
      filter: { frontmatter: { tags: { in: [$primaryTag] }, draft: { ne: true } } }
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          ...BlogPostHeader
        }
      }
    }
  }
`;

export default PageTemplate;
