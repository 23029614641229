import styled from '@emotion/styled';
import { saturate, lighten } from 'polished';
import React, { useState, useEffect } from 'react';

import { colors } from '../../styles/colors';

function getDocHeight(document: Document) {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,

    document.body.offsetHeight,
    document.documentElement.offsetHeight,

    document.body.clientHeight,
    document.documentElement.clientHeight,
  );
}

export const ProgressBar: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    function calculateScrollDistance() {
      const scrollTop = window.pageYOffset;
      const winHeight = window.innerHeight;
      const docHeight = getDocHeight(document);

      const totalDocScrollLength = docHeight - winHeight;
      const scrollPosition = Math.floor((scrollTop / totalDocScrollLength) * 100);

      setScrollPosition(scrollPosition);
    }

    function handleScroll() {
      calculateScrollDistance();
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return <Progress percent={scrollPosition} />;
};

interface ProgressProps {
  percent: number;
}

const Progress = styled.div<ProgressProps>`
  position: fixed;
  background: linear-gradient(
    to right,
    ${saturate('-0.1', lighten('-0.09', colors.primary))} ${props => props.percent}%,
    transparent 0
  );
  width: 100%;
  height: 4px;
  z-index: 1001;
`;
